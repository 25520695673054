.displayed-word {
    padding-bottom: 1em;
}

.displayed-word > span {
    font-size: 2em;
}

.displayed-word > span > input {
    font-size: 1em;
    text-align: center;
    width: 1em;
}