
.horizontal-comparison-equation {
    padding-top: 100px;
}

.horizontal-equation-answer-box {
    flex: 1;
    max-width: 33%;
}

.horizontal-equation-select-box {
    font-size: 3em;
}

.horizontal-operand {
    font-size: 3em;
    text-align: center;
    width: 100%;
}

.vertical-equation-answer-box {
    float: right;
    width: 130px;
}

@keyframes answer-response {
    0% {height: 100px;}
    50% {height: 200px;}
    100% {height: 100px;}
}